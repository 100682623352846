import Layout from '../components/Layout';
import { SEO } from '../components/SEO';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { Component } from 'react';
import { Colors, Tags } from '../components/Text';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Pagination } from 'semantic-ui-react';

const menuLinks = {
  Recipes: '/recipes',
  'Tech Blog': '/blogs',
  'About Me': '/',
};

class Recipes extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);

    let allTags = data.posts.nodes
      .filter((node) => node.fields.slug.startsWith('/recipes'))
      .map((node, index) => node.frontmatter.tags)
      .flat()
      .sort();
    allTags = new Set(allTags);
    allTags = Array.from(allTags);

    const allPosts = data.posts.nodes
      .filter((node) => node.fields.slug.startsWith('/recipes'))
      .map((node) => {
        node.frontmatter.fullSlug = node.fields.slug;
        node.frontmatter.imageFile = node.fields.image;
        return node.frontmatter;
      })
      .sort((post1, post2) => {
        let dateDiff = new Date(post1.date) - new Date(post2.date);
        return dateDiff !== 0 ? dateDiff : post1.title - post2.title;
      });

    this.state = {
      allTags: allTags,
      allPosts: allPosts,
      currentTag: null,
      currentPosts: allPosts,
      numPostsPerPage: 10,
      currentPage: 0,
      searchQuery: "",
    };
  }

  handleSearchInputChange = (event) => {
    const query = event.target.value

    const filteredPosts = this.state.allPosts.filter(post => {
      let { description, title, author, tags } = post

      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
          title.toLowerCase().includes(query.toLowerCase()) ||
          author.toLowerCase().includes(query.toLowerCase()) ||
          (tags && tags.join(" ").toLowerCase().includes(query.toLowerCase()))
      )
    });

    let newState = {
      currentPage: 0,
      searchQuery: query,
      currentPosts: filteredPosts
    };
    this.setState(newState);
  }

  handlePaginationChange = (e, { activePage }) => {
    let newState = {
      currentPage: activePage - 1,
    };
    this.setState(newState);
  }

  getHighlightedText(text, highlight) {
      // Split text on highlight term, include term itself into parts, ignore case
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <mark>{part}</mark> : part)}</span>;
  }

  renderPost(post, index) {
    return (
      <section className="spotlight" key={index}>
        {/*<div className="image">*/}
        {/*  <img src={post.image ? post.image : defaultImage} alt={post.title} />*/}
        {/*</div>*/}
        <div className="image">
          <Img fluid={post.imageFile.childImageSharp.fluid} alt={post.title} />
        </div>
        <div className="content">
          <h2 className="recipe-title">
            <AniLink cover direction="right" hex={Colors.LIGHTBLUE} to={post.fullSlug}>
              {this.getHighlightedText(post.title, this.state.searchQuery)}
            </AniLink>
          </h2>
          <p className="author">By {this.getHighlightedText(post.author, this.state.searchQuery)}</p>
          <p>
            <em>{this.getHighlightedText(post.description, this.state.searchQuery)}</em>
          </p>
          <Tags backgroundColor={Tags.BackgroundColor.RED} labels={post.tags} />
        </div>
      </section>
    );
  }

  render() {
    let maxPages = Math.ceil(this.state.currentPosts.length / this.state.numPostsPerPage);
    let startIndex = this.state.currentPage * this.state.numPostsPerPage;
    let endIndex = startIndex + this.state.numPostsPerPage;
    return (
      <Layout fullMenu menuLinks={menuLinks} enableSearch handleSearchInputChange={this.handleSearchInputChange}>
        <SEO title="Recipes" description="Kunal Kapoor's Delicious Recipes" />
        <article id="main">
          <section className="wrapper style_recipe">
            {/*<div className="inner">*/}
            {/*<Tags backgroundColor={Tags.BackgroundColor.RED} labels={this.state.allTags} />*/}
            {/*<hr />*/}
            {this.state.currentPosts.slice(startIndex, endIndex).map((post, index) => this.renderPost(post, index))}
            {/*</div>*/}
            <br />
            <div className="pagination-wrapper">
              <Pagination
                activePage={this.state.currentPage + 1}
                totalPages={maxPages}
                onPageChange={this.handlePaginationChange}
              />
            </div>
          </section>
        </article>
      </Layout>
    );
  }
}

export default Recipes;

export const query = graphql`
  query {
    posts: allMarkdownRemark {
      nodes {
        fields {
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        frontmatter {
          title
          description
          author
          slug
          date
          image
          tags
          time
          servings
        }
      }
    }
  }
`;
